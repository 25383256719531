import { ApiEnvelope, ApiEnvelopeNoContent, RecursivePartial } from '@optimization/ssi-common';
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';
import { SOLUTION_ENDPOINT } from 'app/config';
import {
  CreateDepotRequestV2VM,
  CreateDepotResponseV2VM,
  CreateManualVehicleRequestV2VM,
  DepotTransformed,
  EnvironmentalImpactVM,
  FactBasicDataResponse,
  GetDepotResponseV2VM,
  GetVehicleResponseV2VM,
  ListDepotResponseV2VM,
  ListDepotTransformed,
  ListVehicleResponseV2VM,
  ListVehicleTransformed,
  MapLocationV2VM,
  PresentationExportRequestVM,
  PresentationExportResponseVM,
  PtoDevices,
  PtoDeviceVM,
  SolutionMetadataTransformed,
  SolutionMetadataVM,
  SolutionTransformed,
  SolutionVM,
  Stops,
  StopsMetadataRequestVM,
  StopsMetadataWithTimeWindowResponseVM,
  transformDepot,
  transformListDepot,
  transformListVehicle,
  transformSolution,
  transformSolutionMetadata,
  transformVehicle,
  Trips,
  UpdateChargingEventsRequestVM,
  UpdateDepotRequestV2VM,
  UpdateMultipleVehicleV2VM,
  UpdateVehicleResponseV2VM,
  UserVM,
  ValidateChargingEventRequestVM,
  ValidateChargingEventResponseVM,
  ValidateChassisNumbersV2VM,
  ValidateChassisNumbersVehicleResponseVM,
  ValidateDepotSetupResponseV2VM,
  VehicleTcoRequestVM,
  VehicleTcoResponseVM,
  VehicleTransformed,
  QueryStopsRequestVM,
} from '@optimization/sa-common';

type ValidateDepotSetupApiEnvelope = ApiEnvelope<ValidateDepotSetupResponseV2VM>;

type ValidateChargingEventApiEnvelope = ApiEnvelope<ValidateChargingEventResponseVM>;

type SolutionMetadataApiEnvelope = ApiEnvelope<{ Items: SolutionMetadataVM[] }>;

type ListDepotApiEnvelope = ApiEnvelope<{ Items: ListDepotResponseV2VM[] }>;

type UpdateVehicleApiEnvelope = ApiEnvelope<UpdateVehicleResponseV2VM>;

type SolutionApiEnvelope = ApiEnvelope<SolutionVM>;

type GetDepotApiEnvelopeV2 = ApiEnvelope<GetDepotResponseV2VM>;

type CreateDepotApiEnvelopeV2 = ApiEnvelope<CreateDepotResponseV2VM>;

type CalculateTcoApiEnvelope = ApiEnvelope<VehicleTcoResponseVM>;

type SolutionPresentationEnvelope = ApiEnvelope<PresentationExportResponseVM>;

interface CreateDepotArgsV2 extends CreateDepotRequestV2VM {
  solutionId: number | string;
}

interface UpdateDepotArgsV2 extends UpdateDepotRequestV2VM {
  solutionId: number | string;
  depotId: number;
}

type GetVehicleApiEnvelopeV2 = ApiEnvelope<GetVehicleResponseV2VM>;

type ListVehicleApiEnvelopeV2 = ApiEnvelope<{
  Items: ListVehicleResponseV2VM[];
}>;

interface GetVehicleArgsV2 {
  solutionId: number | string;
  vehicleId: number;
}

interface DeleteVehicleChargingEventsArgsV2 {
  solutionId: number | string;
  vehicleId: number;
  noInvalidates?: boolean;
}

interface UpdateVehicleChargingEventsArgsV2 extends UpdateChargingEventsRequestVM {
  solutionId: number | string;
  vehicleId: number;
}

interface ValidateVehicleChargingEventsArgsV2 extends ValidateChargingEventRequestVM {
  solutionId: number | string;
  vehicleId: number;
}

interface ChassisNumbersBodyV2 extends ValidateChassisNumbersV2VM {
  solutionId: number | string;
}

interface CreateManualVehicleArgsV2 extends CreateManualVehicleRequestV2VM {
  solutionId: number | string;
  noInvalidates?: boolean;
}

interface UpdateVehicleArgsV2 {
  solutionId: number | string;
  vehicle: GetVehicleResponseV2VM;
  noInvalidates?: boolean;
}

interface MapLocationV2Args {
  solutionId: number | string;
  mapLocation: MapLocationV2VM;
}

interface PresentationExportArgs extends PresentationExportRequestVM {
  solutionId: number | string;
}
type MapLocationApiEnvelopeV2 = ApiEnvelope<MapLocationV2VM>;

type UserApiEnvelope = ApiEnvelope<UserVM>;

type PtoApiEnvelope = ApiEnvelope<{ [key: string]: PtoDeviceVM }>;

type ValidateChassisNumbersVehicleApiEnvelopeV2 = ApiEnvelope<ValidateChassisNumbersVehicleResponseVM>;

interface InitStopsArgs extends QueryStopsRequestVM {
  solutionId: number | string;
}

interface InitTripsArgs extends InitStopsArgs {}

interface InitStopsResponse {
  QueryId: string;
}
interface InitTripsResponse extends InitStopsResponse {}

type StopsApiEnvelope = ApiEnvelope<Stops>;
type TripsApiEnvelope = ApiEnvelope<Trips>;

type InitStopsApiEnvelope = ApiEnvelope<InitStopsResponse>;
type InitTripsApiEnvelope = ApiEnvelope<InitTripsResponse>;

interface StopsStatus {
  ExecutionStatus: string;
}
interface TripsStatus extends StopsStatus {}

type StopsStatusApiEnvelope = ApiEnvelope<StopsStatus>;
type TripsStatusApiEnvelope = ApiEnvelope<TripsStatus>;

interface StopsArgs {
  solutionId: number | string;
  queryId: string;
}
interface TripsArgs extends StopsArgs {}

interface StopsStatusArgs {
  solutionId: number | string;
  queryId: string;
}
interface TripsStatusArgs extends StopsStatusArgs {}

type StopsMetadataApiEnvelope = ApiEnvelope<StopsMetadataWithTimeWindowResponseVM>;

interface StopsMetadataArgs extends StopsMetadataRequestVM {
  solutionId: number | string;
}

type FactBasicDataEnvelope = ApiEnvelope<FactBasicDataResponse>;

interface DeleteDepotsArgs {
  solutionId: number | string;
  ids: number[];
}

interface DeleteVehiclesArgs {
  solutionId: number | string;
  ids: number[];
  noInvalidates?: boolean;
}

interface UpdateMultipleVehicleArgs extends UpdateMultipleVehicleV2VM {
  solutionId: number | string;
}

interface VehicleTcoArgs extends VehicleTcoRequestVM {
  solutionId: number | string;
  vehicleId: number;
}

const baseQuery = fetchBaseQuery({
  baseUrl: `${SOLUTION_ENDPOINT}/api`,
  prepareHeaders: (headers, { getState }) => {
    const idToken = (getState() as RootState).auth.idToken;
    const xSessionId = sessionStorage.getItem('x_session_id');
    if (idToken) {
      headers.set('authorization', `Bearer ${idToken}`);
    }
    if (xSessionId) {
      headers.set('x-session-id', xSessionId);
    }
    return headers;
  },
});

const baseQueryRetry = retry(baseQuery, {
  maxRetries: 0,
});

export const tagTypes = ['Depots', 'Solutions', 'User', 'Vehicles', 'EnvironmentalImpact'] as const;

export const solutionApi = createApi({
  reducerPath: 'solutionApi',
  tagTypes,
  baseQuery: baseQueryRetry,
  endpoints: (build) => ({
    getSolutions: build.query<SolutionMetadataTransformed[], void>({
      query: () => ({ url: 'v1/Solutions' }),
      providesTags: (solutions) => {
        const tags: any = ['Solutions' as const];

        if (solutions) {
          for (const solution of solutions) {
            tags.push({ type: 'Solutions' as const, id: solution.Id });
          }
        }

        return tags;
      },
      transformResponse: (response: SolutionMetadataApiEnvelope) =>
        response.Content.Items.map((solution) => transformSolutionMetadata(solution)),
    }),
    getSolution: build.query<SolutionTransformed, number | string>({
      query: (id) => ({ url: `v1/Solutions/${id}` }),
      providesTags: (_, __, id) => [{ type: 'Solutions', id }],
      transformResponse: (response: SolutionApiEnvelope) => transformSolution(response.Content),
    }),
    addSolution: build.mutation<SolutionApiEnvelope, Partial<SolutionTransformed>>({
      query: (body) => ({ url: 'v1/Solutions', method: 'POST', body }),
      invalidatesTags: ['Solutions'],
    }),
    deleteSolution: build.mutation<any, { solutionId: number | string }>({
      query: ({ solutionId }) => ({
        url: `v1/Solutions/${solutionId}`,
        method: 'DELETE',
      }),
    }),
    updateSolution: build.mutation<null, Partial<SolutionTransformed>>({
      query: ({ Id, ...body }) => ({
        url: `v1/Solutions/${Id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, __, { Id }) => [{ type: 'Solutions', id: Id }],
    }),
    getVehicles: build.query<ListVehicleTransformed[], string>({
      query: (solutionId) => ({ url: `v2/Solutions/${solutionId}/Vehicles` }),
      providesTags: (vehicles) => {
        const tags: any = ['Vehicles' as const];

        if (vehicles) {
          for (const vehicle of vehicles) {
            tags.push({ type: 'Vehicles' as const, id: vehicle.Id });
          }
        }

        return tags;
      },
      transformResponse: (response: ListVehicleApiEnvelopeV2) =>
        response.Content.Items.map((item) => transformListVehicle(item)),
    }),
    getVehicle: build.query<VehicleTransformed, GetVehicleArgsV2>({
      query: ({ solutionId, vehicleId }) => ({
        url: `v2/Solutions/${solutionId}/Vehicles/${vehicleId}`,
      }),
      providesTags: (_, __, { vehicleId }) => [{ type: 'Vehicles', id: vehicleId }],
      transformResponse: (response: GetVehicleApiEnvelopeV2) => transformVehicle(response.Content),
    }),
    validateChassis: build.mutation<ValidateChassisNumbersVehicleResponseVM, ValidateChassisNumbersV2VM>({
      query: (body) => ({
        url: `v1/ChassisNumbers/Validate`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: ValidateChassisNumbersVehicleApiEnvelopeV2) => response.Content,
    }),
    createManualVehicle: build.mutation<ApiEnvelopeNoContent, CreateManualVehicleArgsV2>({
      query: ({ solutionId, ...body }) => ({
        url: `v2/Solutions/${solutionId}/Vehicles/Manual`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, { solutionId, noInvalidates }) =>
        noInvalidates ? [] : [{ type: 'Solutions', id: solutionId }, 'Vehicles'],
    }),
    addChassisToSolution: build.mutation<ValidateChassisNumbersVehicleResponseVM, ChassisNumbersBodyV2>({
      query: ({ solutionId, ...body }) => ({
        url: `v2/Solutions/${solutionId}/Vehicles/Chassis`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: ValidateChassisNumbersVehicleApiEnvelopeV2) => response.Content,
      invalidatesTags: (_, __, { solutionId }) => [{ type: 'Solutions', id: solutionId }, 'Vehicles'],
    }),
    updateVehicle: build.mutation<UpdateVehicleResponseV2VM, UpdateVehicleArgsV2>({
      query: ({ solutionId, vehicle }) => {
        const { ...body } = vehicle;
        return {
          url: `v2/Solutions/${solutionId}/Vehicles/${vehicle.Id}`,
          method: 'PUT',
          body,
        };
      },
      transformResponse: (response: UpdateVehicleApiEnvelope) => response.Content,
      invalidatesTags: (updateVehicleResponseV2VM, __, { vehicle, noInvalidates }) => {
        const tags: any[] = [{ type: 'EnvironmentalImpact' as const, id: vehicle.Id }];

        if (!noInvalidates) {
          if (updateVehicleResponseV2VM?.UpdateAll) {
            tags.push('Vehicles' as const);
            tags.push('Depots' as const);
          } else {
            tags.push({ type: 'Vehicles' as const, id: vehicle.Id });

            if (vehicle.HomeDepotId) {
              tags.push({
                type: 'Depots' as const,
                id: vehicle.HomeDepotId,
              });
            }
          }
        }

        return tags;
      },
    }),
    updateMultipleVehicles: build.mutation<ApiEnvelopeNoContent, UpdateMultipleVehicleArgs>({
      query: ({ solutionId, ...body }) => ({
        url: `v2/Solutions/${solutionId}/Vehicles/Multiple`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, __, { Ids }) => Ids.map((id) => ({ type: 'Vehicles', id })),
    }),
    validateVehicleChargingEvents: build.mutation<ValidateChargingEventResponseVM, ValidateVehicleChargingEventsArgsV2>(
      {
        query: ({ solutionId, vehicleId, PerformanceStep, ...body }) => ({
          url: `v2/Solutions/${solutionId}/Vehicles/${vehicleId}/ChargingEvents/${PerformanceStep}/Validate`,
          method: 'POST',
          body,
        }),
        transformResponse: (response: ValidateChargingEventApiEnvelope) => response.Content,
      },
    ),
    updateVehicleChargingEvents: build.mutation<ApiEnvelopeNoContent, UpdateVehicleChargingEventsArgsV2>({
      query: ({ solutionId, vehicleId, PerformanceStep, ...body }) => ({
        url: `v2/Solutions/${solutionId}/Vehicles/${vehicleId}/ChargingEvents/${PerformanceStep}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['Vehicles', 'Depots'],
    }),
    deleteChargingEvents: build.mutation<ApiEnvelopeNoContent, DeleteVehicleChargingEventsArgsV2>({
      query: ({ solutionId, vehicleId }) => ({
        url: `v2/Solutions/${solutionId}/Vehicles/${vehicleId}/ChargingEvents`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { vehicleId, noInvalidates }) =>
        noInvalidates ? [] : [{ type: 'Vehicles', id: vehicleId }, 'Depots'],
    }),
    getDepots: build.query<ListDepotTransformed[], string>({
      query: (solutionId) => ({ url: `v2/Solutions/${solutionId}/Depots` }),
      providesTags: (depots) => {
        const tags: any = ['Depots' as const];

        if (depots) {
          for (const depot of depots) {
            tags.push({ type: 'Depots' as const, id: depot.DepotId });
          }
        }

        return tags;
      },
      transformResponse: (response: ListDepotApiEnvelope) =>
        response.Content.Items.map((depot) => transformListDepot(depot)),
    }),
    getDepot: build.query<DepotTransformed, { solutionId: string; depotId: string }>({
      query: ({ solutionId, depotId }) => ({
        url: `v2/Solutions/${solutionId}/Depots/${depotId}`,
      }),
      providesTags: (_, __, { depotId }) => [{ type: 'Depots', id: depotId }],
      transformResponse: (response: GetDepotApiEnvelopeV2) => transformDepot(response.Content),
    }),
    addDepot: build.mutation<CreateDepotResponseV2VM, CreateDepotArgsV2>({
      query: ({ solutionId, ...body }) => ({
        url: `v2/Solutions/${solutionId}/Depots`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, { solutionId, MappedVehicles }) => {
        const tags: any[] = [{ type: 'Solutions' as const, id: solutionId }, 'Depots'];

        for (const vehicleId of MappedVehicles) {
          tags.push({ type: 'Vehicles' as const, id: vehicleId });
        }

        return tags;
      },
      transformResponse: (response: CreateDepotApiEnvelopeV2) => response.Content,
    }),
    updateDepot: build.mutation<ApiEnvelopeNoContent, UpdateDepotArgsV2>({
      query: ({ solutionId, depotId, ...body }) => ({
        url: `v2/Solutions/${solutionId}/Depots/${depotId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, __, { solutionId }) => [{ type: 'Solutions', id: solutionId }, 'Depots', 'Vehicles'],
    }),
    deleteDepots: build.mutation<ApiEnvelopeNoContent, DeleteDepotsArgs>({
      query: ({ solutionId, ids }) => {
        const queryIds = ids.map((id) => `Ids=${id}`).join('&');
        return {
          url: `v2/Solutions/${solutionId}/Depots?${queryIds}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: () => ['Depots', 'Vehicles', 'Solutions'],
    }),
    validateDepotSetup: build.mutation<ValidateDepotSetupResponseV2VM, CreateDepotArgsV2>({
      query: ({ solutionId, ...body }) => ({
        url: `v2/Solutions/${solutionId}/Depots/Validate`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: ValidateDepotSetupApiEnvelope) => response.Content,
    }),
    addMapLocation: build.mutation<MapLocationApiEnvelopeV2, RecursivePartial<MapLocationV2Args>>({
      query: ({ solutionId, mapLocation }) => {
        const { ...body } = mapLocation;
        return {
          url: `v2/Solutions/${solutionId}/Map/Locations`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (_, __, { solutionId }) => [{ type: 'Solutions', id: solutionId }, 'Vehicles'],
    }),
    updateMapLocation: build.mutation<MapLocationApiEnvelopeV2, RecursivePartial<MapLocationV2Args>>({
      query: ({ solutionId, mapLocation }) => {
        const { ...body } = mapLocation;
        return {
          url: `v2/Solutions/${solutionId}/Map/Locations/${mapLocation?.Id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (_, __, { solutionId }) => [{ type: 'Solutions', id: solutionId }, 'Vehicles'],
    }),
    deleteMapLocation: build.mutation<MapLocationApiEnvelopeV2, { solutionId: number | string; mapLocationId: number }>(
      {
        query: ({ solutionId, mapLocationId }) => ({
          url: `v2/Solutions/${solutionId}/Map/Locations/${mapLocationId}`,
          method: 'DELETE',
        }),
        invalidatesTags: (_, __, { solutionId }) => [{ type: 'Solutions', id: solutionId }, 'Vehicles'],
      },
    ),
    getUser: build.query<UserVM, void>({
      query: () => ({
        url: 'v1/Users/self',
      }),
      providesTags: () => ['User'],
      transformResponse: (response: UserApiEnvelope) => response.Content,
    }),
    updateOnboarding: build.mutation<null, void>({
      query: () => ({
        url: 'v1/Users/Onboarding',
        method: 'PUT',
      }),
      invalidatesTags: () => ['User'],
    }),
    updateTos: build.mutation<null, void>({
      query: () => ({
        url: 'v1/Users/tos',
        method: 'PUT',
      }),
      invalidatesTags: () => ['User'],
    }),
    initStops: build.mutation<InitStopsResponse, InitStopsArgs>({
      query: ({ solutionId, ...body }) => ({
        url: `v1/Solutions/${solutionId}/Stops/Init`,
        method: 'POST',
        body,
      }),
      extraOptions: { maxRetries: 2 },
      transformResponse: (response: InitStopsApiEnvelope) => response.Content,
    }),
    getStopsStatus: build.query<StopsStatus, StopsStatusArgs>({
      query: ({ solutionId, queryId }) => ({
        url: `v1/Solutions/${solutionId}/Stops/Status/${queryId}`,
      }),
      extraOptions: { maxRetries: 2 },
      transformResponse: (response: StopsStatusApiEnvelope) => response.Content,
    }),
    getStops: build.query<Stops, StopsArgs>({
      query: ({ solutionId, queryId }) => ({
        url: `v1/Solutions/${solutionId}/Stops/Results/${queryId}`,
      }),
      transformResponse: (response: StopsApiEnvelope) => response.Content,
    }),
    getStopsMetadata: build.mutation<StopsMetadataWithTimeWindowResponseVM, StopsMetadataArgs>({
      query: ({ solutionId, VehicleIds }) => ({
        url: `v1/Solutions/${solutionId}/Stops/Metadata`,
        method: 'POST',
        body: { VehicleIds },
      }),
      transformResponse: (response: StopsMetadataApiEnvelope) => response.Content,
    }),
    initTrips: build.mutation<InitTripsResponse, InitTripsArgs>({
      query: ({ solutionId, ...body }) => ({
        url: `v1/Solutions/${solutionId}/Trips/Init`,
        method: 'POST',
        body,
      }),
      extraOptions: { maxRetries: 2 },
      transformResponse: (response: InitTripsApiEnvelope) => response.Content,
    }),
    getTripsStatus: build.query<TripsStatus, TripsStatusArgs>({
      query: ({ solutionId, queryId }) => ({
        url: `v1/Solutions/${solutionId}/Trips/Status/${queryId}`,
      }),
      extraOptions: { maxRetries: 2 },
      transformResponse: (response: TripsStatusApiEnvelope) => response.Content,
    }),
    getTrips: build.query<Trips, TripsArgs>({
      query: ({ solutionId, queryId }) => ({
        url: `v1/Solutions/${solutionId}/Trips/Results/${queryId}`,
      }),
      transformResponse: (response: TripsApiEnvelope) => response.Content,
    }),
    getFactBasicData: build.query<FactBasicDataResponse, void>({
      query: () => ({ url: 'v1/FactBasicData' }),
      transformResponse: (response: FactBasicDataEnvelope) => response.Content,
    }),
    getPtoDevices: build.query<PtoDevices, void>({
      query: () => ({
        url: `v1/Pto`,
      }),
      transformResponse: (response: PtoApiEnvelope) => response.Content,
    }),
    deleteVehicles: build.mutation<ApiEnvelopeNoContent, DeleteVehiclesArgs>({
      query: ({ solutionId, ids }) => {
        const queryIds = ids.map((id) => `ids=${id}`).join('&');
        return {
          url: `v2/Solutions/${solutionId}/Vehicles?${queryIds}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (_, __, { noInvalidates }) => (noInvalidates ? [] : ['Depots', 'Solutions', 'Vehicles']),
    }),
    calculateVehicleTcoFigures: build.query<CalculateTcoApiEnvelope, VehicleTcoArgs>({
      query: ({ solutionId, vehicleId, ...body }) => ({
        url: `v2/Solutions/${solutionId}/tco/${vehicleId}/calculate`,
        method: 'POST',
        body,
      }),
    }),
    getPresentationData: build.query<SolutionPresentationEnvelope, PresentationExportArgs>({
      query: ({ solutionId, ...body }) => ({
        url: `v2/Solutions/${solutionId}/Export`,
        method: 'POST',
        body,
      }),
    }),
    getEnvironmentalImpact: build.query<EnvironmentalImpactVM, { solutionId: string; vehicleId: number }>({
      query: ({ solutionId, vehicleId }) => ({
        url: `v2/Solutions/${solutionId}/Vehicles/${vehicleId}/EnvironmentalImpact`,
      }),
      transformResponse: (response: ApiEnvelope<EnvironmentalImpactVM>) => response.Content,
      keepUnusedDataFor: 60 * 60 * 24 * 7,
      providesTags: (_, __, { vehicleId }) => [{ type: 'EnvironmentalImpact', id: vehicleId }],
    }),
  }),
});

export const {
  useAddSolutionMutation,
  useAddChassisToSolutionMutation,
  useAddDepotMutation,
  useAddMapLocationMutation,
  useCreateManualVehicleMutation,
  useDeleteChargingEventsMutation,
  useDeleteDepotsMutation,
  useDeleteSolutionMutation,
  useDeleteMapLocationMutation,
  useDeleteVehiclesMutation,
  useGetFactBasicDataQuery,
  useGetDepotQuery,
  useGetDepotsQuery,
  useGetEnvironmentalImpactQuery,
  useGetSolutionsQuery,
  useGetSolutionQuery,
  useGetStopsMetadataMutation,
  useGetStopsStatusQuery,
  useGetStopsQuery,
  useGetTripsStatusQuery,
  useGetTripsQuery,
  useGetPtoDevicesQuery,
  useGetUserQuery,
  useGetVehicleQuery,
  useGetVehiclesQuery,
  useInitStopsMutation,
  useInitTripsMutation,
  useUpdateDepotMutation,
  useUpdateSolutionMutation,
  useUpdateMapLocationMutation,
  useUpdateOnboardingMutation,
  useUpdateTosMutation,
  useUpdateMultipleVehiclesMutation,
  useUpdateVehicleMutation,
  useUpdateVehicleChargingEventsMutation,
  useValidateDepotSetupMutation,
  useValidateChassisMutation,
  useCalculateVehicleTcoFiguresQuery,
  useValidateVehicleChargingEventsMutation,
  useGetPresentationDataQuery,
} = solutionApi;
